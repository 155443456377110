<template lang="pug">
.container(
  @click.self.stop="$emit('emit', false)"
  v-if="visible"
)
  .modal
    Button.modal__button(
      @click="$emit('close', false)"
      color="red"
      text=""
      :icon=['fas', 'times']
    )

    slot()
</template>

<script>
// import {
//   mapGetters,
//   mapActions
// } from 'vuex'
import {
  Button
} from '@/components/atoms'

export default {
  name: 'Modal',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false
    }
  },
  components: {
    Button
  }
}
</script>

<style lang="sass" scoped>
  .container
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    width: 100%
    height: 100%
    background: #00000066
    display: flex
    align-items: center
    justify-content: center
    max-height: 100vh
    .modal
      background: white
      max-height: 100vh
      min-height: 360px
      min-width: 416px
      display: flex
      position: absolute
      .modal__button
        margin: 8px 8px 0
        position: absolute
        right: 0
</style>
